import React from 'react';
import { PageProps } from 'gatsby';
import InfoPage from '@/components/generics/infoPage';
import Message from '@/components/message';
import errorIcon from '@/images/error_illus.png';

type ErrorLocState = {
  message?: string;
  ctaText?: string;
};

const Error: React.FC<PageProps<null, null, ErrorLocState>> = ({
  location,
}) => (
  <InfoPage>
    <Message
      iconUrl={errorIcon}
      copy={
        location.state?.message ||
        'Tenemos problemas para conectarnos a internet. Volvé a intentalo más tarde.'
      }
      ctaText={location.state?.ctaText || 'Reintentar'}
    />
  </InfoPage>
);

export default Error;
